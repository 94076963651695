import { defaultFormatter, currencyFormatter, delimiterFormatter, photoCellRenderer, buildingNameRenderer } from './formatters';
import { unitRowSelected, updateValue, calculateMonthlyValues } from './gridActions';

export function loadUnits(data) {
  const unitsGridElement = document.getElementById('unitsGrid');
  unitsGridElement.innerHTML = "";

  const unitArray = [
    { field: "company_location_unit_id", hide: true },
    { field: "unit_id", hide: true },
    {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      pinned: true,
      width: 20,
    },
    { headerName: "Suite Name", field: "name", pinned: true },
    { headerName: "Building", field: "location", pinned: true, editable: false, cellRenderer: buildingNameRenderer },
    { headerName: "Photos", field: "photos", cellRenderer: photoCellRenderer },
    { headerName: "SF", field: "square_footage", valueFormatter: delimiterFormatter, type: 'number' },
    { headerName: "SF (high range)", field: "high_square_footage", valueFormatter: delimiterFormatter },
    { headerName: "Rate", field: "rate", valueFormatter: currencyFormatter },
    { headerName: "Rate (high range)", field: "high_rate", valueFormatter: currencyFormatter },
    { headerName: "OPEX", field: "opex", valueFormatter: currencyFormatter },
    { headerName: "Electric Cost", field: "electric_costs", valueFormatter: currencyFormatter },
    { headerName: "Monthly Cost", field: "monthly_cost", valueFormatter: currencyFormatter },
    { headerName: "Monthly Cost (high range)", field: "high_monthly_cost", valueFormatter: currencyFormatter },
    { headerName: "Lease Expiration", field: "lease_expiration", cellEditor: "agDateCellEditor" },
    { headerName: "Date Available", field: "date_available", cellEditor: "agDateCellEditor" },
    {
      headerName: "Service Type",
      field: "rate_type",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: data.rate_types
      },
    },
    {
      headerName: "Lease Type",
      field: "lease_type",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: data.lease_types
      },
    },
    { headerName: "Term (mos)", field: "lease_term", valueFormatter: defaultFormatter },
    {
      headerName: "CAM Type",
      field: "cam_type",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Electric Cost", "Janitorial Cost", "Elec. + Jan. Cost"]
      },
    },
    {
      headerName: "Rate Frequency",
      field: "rate_frequency",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["per year", "per month"]
      },
      valueFormatter: defaultFormatter,
    },
  ];

  data.unit_features.forEach(function(feature) {
    unitArray.push({ field: feature, editable: true });
  });

  const unitGridOptions = {
    columnDefs: unitArray,
    rowData: data.units,
    rowSelection: "multiple",
    autoSizeStrategy: {
      type: "fitCellContents",
      defaultMinWidth: 50,
    },
    suppressRowClickSelection: true,
    onRowSelected: unitRowSelected,
    defaultColDef: {
      editable: true,
    },
    onGridReady: function(params) {
      params.api.sizeColumnsToFit();
    },
    onCellEditingStopped: function(event) {
      calculateMonthlyValues(event);
      // updateValue(`/units/${event.data.unit_id}`, event.column.colId, event.value, "unit", "PATCH");
      if (data.unit_features.includes(event.column.colId)) {
          updateValue(`/units/${event.data.unit_id}`, event.column.colId, event.value, "unit_feature", "patch");
        } else {
          updateValue(`/units/${event.data.unit_id}`, event.column.colId, event.value, "unit", "PATCH");
        }
    },
    enterNavigatesVerticallyAfterEdit: true,
    domLayout: 'autoHeight',
    singleClickEdit: true,
    stopEditingWhenCellsLoseFocus: true
  };

  window.unitGrid = agGrid.createGrid(unitsGridElement, unitGridOptions);
}
