import consumer from "./consumer"

consumer.subscriptions.create("PdfChannel", {

  connected() {
    // console.log("connected to route channel")
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    if (data["is_error"] == true) {
      toastr.error(data["message"]);
    } else {
      if (location.pathname == data["path"] && data["is_done"] == true) {
        toastr.success(data["message"]);
        if (data["path"].includes("units")) {
          // resubmit form
          var id = "#edit_unit_" + data["unit_id"];
          var button = $(id).find(":submit")[0]
          $(button).click();
        } else {
          location.reload();
        }

      } else {
        toastr.success(data["message"], "", {timeOut: 3000, extendedTimeOut: 0})
      }
    }
  }
});