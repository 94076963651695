import { locationRowSelected, updateValue } from './gridActions';
import { photoCellRenderer, flyerCellRenderer } from './formatters';

export function loadLocations(data) {
  const buildingsGridElement = document.getElementById('buildingsGrid');
  buildingsGridElement.innerHTML = "";

  // const groupMappings = { nil: "None" };
  const groupMappings = {  };
  data.groups.forEach(group => {
    groupMappings[group.id] = group.name;
  });
  
  const submarketMappings = { nil: "None" };
  data.submarkets.forEach(submarket => {
    submarketMappings[submarket.id] = submarket.name;
  });

  const buildingArray = [
    { field: "company_location_id", hide: true },
    { field: "location_id", hide: true },
    {
      field: "",
      headerCheckboxSelection: true,
      checkboxSelection: true,
      pinned: true,
      width: 20,
    },
    {
      field: "position",
      pinned: true,
      rowDrag: true
    },
    { field: "name", pinned: true, editable: true },
    { field: "city" },
    { headerName: "Photos", field: "photos", cellRenderer: photoCellRenderer },
    { headerName: "Flyers", field: "flyers_count", cellRenderer: flyerCellRenderer },
    {
      headerName: "Group",
      field: "group_id",
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [null, ...Object.keys(groupMappings)]
      },
      refData: groupMappings
    },
    {
      headerName: "Submarket",
      field: "sub_market_id",
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [null, ...Object.keys(submarketMappings)]
      },
      refData: submarketMappings
    },
    { 
      headerName: "Notes",
      field: "notes",
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      editable: true,
      cellEditorParams: {
        maxLength: 100
      }
    },
    {
      headerName: "Status",
      field: "state",
      editable: true,
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: [null, ...data.states]
      },
    },
  ];

  data.features.forEach(function(feature) {
    buildingArray.push({ field: feature, editable: true });
  });

  const buildingGridOptions = {
    columnDefs: buildingArray,
    rowData: data.locations,
    autoSizeStrategy: {
      type: "fitCellContents",
      defaultMinWidth: 50,
    },
    rowSelection: "multiple",
    suppressRowClickSelection: true,
    onRowSelected: locationRowSelected,
    rowDragManaged: true,
    onRowDragEnd: function(event) {
      const array = [];
      window.buildingGrid.forEachNode(function(node) {
        array.push(node.data.company_location_id);
      });
      console.log(array);
      updateValue(`/company_locations/${array[0]}/update-order`, "ids", array, "company_location", "get", true);
    },
    onCellEditingStopped: function(event) {
      const relevantColumns = ["name", "sub_market_id", "group_id", "notes", "state"];
      if (relevantColumns.includes(event.column.colId)) {
        updateValue(`/companies/${data.id}/locations/${event.data.location_id}`, event.column.colId, event.value, "location", "patch");
      } else {
        updateValue(`/companies/${data.id}/locations/${event.data.location_id}`, event.column.colId, event.value, "feature", "patch");
      }
    },
    enterNavigatesVerticallyAfterEdit: true,
    domLayout: 'autoHeight',
    singleClickEdit: true,
    stopEditingWhenCellsLoseFocus: true
  };

  window.buildingGrid = agGrid.createGrid(buildingsGridElement, buildingGridOptions);

}
