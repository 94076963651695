$(document).on('turbolinks:load', function () {

    var infowindow = null;
    var markerInfoWindow = null;
    var map;
    var markers = [];
    var activeHoverMarker;
    var activeMarker;
    var lat;
    var lng;
    var slug;
    var share;
    var aerial;
    var hideEmployees;

    

    var mapElement = document.getElementById('map'); // Assuming your map element has an ID of 'map'

    

    function mapType() {
        return aerial ? 'satellite' : 'roadmap';
    }



    window.initMap = async function () {
        const { Map } = await google.maps.importLibrary("maps");
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");



        map = new Map(document.getElementById('map'), {
            center: { lat: parseFloat(lat), lng: parseFloat(lng) },
            zoom: 4,
            mapId: "4504f8b37365c3d0",
            streetViewControl: true,
            mapTypeControl: true,
            fullscreenControl: true,
            disableDefaultUI: false,
            gestureHandling: "greedy",
            clickableIcons: false,
            mapTypeId: mapType(),
        })

        const transitLayer = new google.maps.TransitLayer();
        // Button to toggle the transit layer
        document.getElementById('toggleTransit').addEventListener('click', function () {
            if (transitLayer.getMap()) {
                transitLayer.setMap(null); // Turn off the transit layer
                $(this).css("font-weight", "400")
            } else {
                transitLayer.setMap(map); // Turn on the transit layer
                $(this).css("font-weight", "500")
            }
        });

        window.getLocations();
        
        if (!hideEmployees) {
            window.getEmployees();
        }
        
        
        window.getMarkers();
        mouseFunctions();

        // drawingManager.setMap(map);

    }
    // Set up the drawing manager
    // var drawingManager = new google.maps.drawing.DrawingManager({
    //     // drawingMode: ,
    //     drawingControl: false,
    //     drawingControlOptions: {
    //         position: google.maps.ControlPosition.TOP_CENTER,
    //         drawingModes: ['polygon', 'polyline']
    //     },
    //     polygonOptions: {
    //             editable: true
    //         }
    // });

    // window.drawButtons = function() {
    //     $('#drawPolygonBtn').click(function(){
            
    //         $('#layerModal').modal("hide");
    //         drawingManager.setDrawingMode(google.maps.drawing.OverlayType.POLYGON);
    //     });
    // }

    // google.maps.event.addListener(drawingManager, 'polygoncomplete', function(polygon) {
    //     var path = polygon.getPath();
    //     var coordinates = [];
    
    //     path.forEach(function(latLng) {
    //       coordinates.push({ lat: latLng.lat(), lng: latLng.lng() });
    //     });
        
    //     // debugger;
    //     console.log(coordinates);
    //     // Send the coordinates to the Rails server for saving
    //     // fetch("/polygons", {
    //     //   method: "POST",
    //     //   headers: {
    //     //     "Content-Type": "application/json",
    //     //     "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
    //     //   },
    //     //   body: JSON.stringify({ polygon: { coordinates: coordinates } })
    //     // }).then(function(response) {
    //     //   return response.json();
    //     // }).then(function(data) {
    //     //   console.log("Polygon saved:", data);
    //     // }).catch(function(error) {
    //     //   console.error("Error saving polygon:", error);
    //     // });
    //   });

    //   google.maps.event.addListener(drawingManager, 'polylinecomplete', function(polyline) {
    //     var path = polyline.getPath();
    //     var coordinates = [];

    //     // debugger
    
    //     path.forEach(function(latLng) {
    //       coordinates.push({ lat: latLng.lat(), lng: latLng.lng() });
    //     });
        
    //     // debugger;
    //     console.log(coordinates);
    //     // Send the coordinates to the Rails server for saving
    //     // fetch("/polygons", {
    //     //   method: "POST",
    //     //   headers: {
    //     //     "Content-Type": "application/json",
    //     //     "X-CSRF-Token": document.querySelector("[name=csrf-token]").content
    //     //   },
    //     //   body: JSON.stringify({ polygon: { coordinates: coordinates } })
    //     // }).then(function(response) {
    //     //   return response.json();
    //     // }).then(function(data) {
    //     //   console.log("Polygon saved:", data);
    //     // }).catch(function(error) {
    //     //   console.error("Error saving polygon:", error);
    //     // });
    //   });


    if (mapElement) {
        var lat = $(mapElement).data("lat")
        var lng = $(mapElement).data("lng")
        var slug = $(mapElement).data("slug")
        var share = $(mapElement).data("share")
        var aerial = $(mapElement).data("aerial")
        var hideEmployees = $(mapElement).data("hide-employees")
        window.initMap();
    }

    function mouseFunctions() {
        $(".list-group-item").mouseover(function () {
            var number = $(this).find(".number-pin")[0].innerText
            $.each($(".price-tag"), function (key, value) {
                // debugger;
                $(value).removeClass("active-marker")
                if ($(value)[0].outerText == number) {
                    $(value).addClass("active-marker")
                }
            })
        })

        // remove highlight when you move mouse off card
        $(".list-group-item").mouseout(function () {
            $.each($(".price-tag"), function (key, value) {
                // debugger;
                $(value).removeClass("active-marker")
            })
        });
    }

    window.getLocations = async function () {
        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");
        var locations;
        var bounds = new google.maps.LatLngBounds();
        var locationQuery = $(".search-form").val();

        for (let i = 0; i < markers.length; i++) {
            markers[i].setMap(null);
        }
        markers = []; // Reset the markers array

        locationMarkers = [];
    

        $.ajax({
            url: `/companies/${slug}/locations.json`,
            data: {
                query: locationQuery,
                share: share                
            }
        }).done(function (res) {
            locations = res
            console.log(locations)
            console.log(locationQuery)
            locationQuery = "";

            $.each(locations, function (key, location) {
                const priceTag = document.createElement("div");
                

                if (location.map_icon != null) {                    
                    $(priceTag).html(`<img src=${location.map_icon} class='max-h-40px' style='max-width: 100px!important'>`)
                } else {
                    priceTag.className = "price-tag";
                    priceTag.textContent = location.position
                    if (location.color) {
                        priceTag.style.setProperty('--price-tag-color', location.color);
                    }
                }

                var marker = new AdvancedMarkerElement({
                    position: new google.maps.LatLng(location.address.lat, location.address.lng),
                    map: map,
                    gmpDraggable: !share,
                    content: priceTag,
                });

                marker.groupId = location.group_id

                locationMarkers.push(marker);

                marker.addListener("dragend", (event) => {
                    // console.log(marker.position.lat)
                    updatePosition(marker)
                });

                function updatePosition(marker) {
                    const confirmed = confirm('Do you want to update the marker position?');
                    if (confirmed) {
                        $.ajax({
                            url: `/addresses/${location.address.id}/`,
                            method: "PATCH",
                            dataType: "script",
                            data: {
                                address: {
                                    lat: marker.position.lat,
                                    lng: marker.position.lng,
                                    manual_position: true
                                }
                            }
                        }).done(function (res) {
                            toastr.success("Location Updated")
                        })
                    } else {
                        // marker.setPosition(new google.maps.LatLng(location.address.lat, location.address.lng))
                        marker.position = {lat: location.address.lat,  lng: location.address.lng}
                    }
                }

                bounds.extend(marker.position);

                // Listeners
                google.maps.event.addListener(marker, 'click', function (e) {
                    var markerElement = e.domEvent.target

                    $.each(markers, function (key, marker) {
                        $(marker.element).find(".price-tag").removeClass("active-marker")
                    })

                    $(markerElement).toggleClass("active-marker")

                    activeMarker = marker;
                })

                google.maps.event.addListener(map, 'click', function () {
                    activeMarker = null;
                    if (isMobileDevice()) {
                        clearMapCards()
                    }
                    $.each($(".price-tag"), function (key, value) {
                        // debugger;
                        $(value).removeClass("active-marker")
                    })
                })


                marker.addListener('click', function () {
                    // infowindow.open(map, marker);
                    if (isMobileDevice()) {
                        openMapCard(location.id)
                    } else {
                        openInfoCard(location.id, location.sub_market_id)
                    }

                });

                function openMapCard(locationId) {
                    clearMapCards();
                    $("#location-" + locationId).removeClass("d-none")
                }

                function clearMapCards() {
                    $(".mobile-map-card").not(".d-none").addClass("d-none");
                }

                function openInfoCard(locationId, subMarketId) {
                    // if ($(".locations-list").hasClass("d-none")) {
                    //     $(".locations-list").removeClass("d-none")
                    //     $(".submarkets-list").addClass("d-none")
                    //     $("#toggle-switch").prop("checked", false)
                    // }
                    

                    $(".info-box").removeClass("border-3 border-blue");
                    $(".info-box").addClass("border-light-grey");
                    var dataBox = $("div").find("[data-box-id=" + locationId + "]")
                    $(dataBox).removeClass("border-light-grey");
                    $(dataBox).addClass("border-3 border-blue");
                    // reset vertical position before scrolling to element
                    $(".side-location-panel").scrollTop(0)
                    // find where element is we need to scroll to
                    var offset = $(dataBox).offset().top
                    // scroll to element minus height of box
                    $(".side-location-panel").scrollTop(offset - 220)
                }
                markers.push(marker)
            })

            if (locations.length > 0) {
                map.fitBounds(bounds);
                if (map.zoom > 15) { map.setZoom(15); }
            }

            if (locations.length == 1) {
                map.setZoom(15);
            }

            
            
            window.toggleGroup = async function (group_id) {
                $.each(locationMarkers, function (key, marker) {
                    if (marker.groupId == group_id) {
                        if (marker.map) {
                            marker.map = null;
                          } else {
                            marker.map = map;
                        }
                    }
                });
            }

            

        }); 

    }

    window.getEmployees = async function () {

        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

        $.ajax({
            url: `/companies/${slug}/employees.json`
        }).done(function (res) {
            var employees = res
            // console.log(res)
            var activeMarker = null;
            var icon = '/assets/map-pins/employee.svg';
            var icon_hover = '/assets/map-pins/employee.svg';
            var icon_selected = '/assets/map-pins/employee-selected.svg';


            var employeeMarkers = employees.map(function (employee, i) {
                var labelText = employee.initials

                const employeeTag = document.createElement("div");
                employeeTag.className = "employee-tag";
                employeeTag.textContent = employee.initials

                var marker = new AdvancedMarkerElement({
                    // position: { lat: location.address.lat, lng: location.address.lng },
                    position: new google.maps.LatLng(employee.address.lat, employee.address.lng),
                    map: map,
                    content: employeeTag
                });

                var included;
                if (employee.is_included) {
                    included = ""
                } else {
                    included = "<i class='color-grey font-size-12'>(not included)</i>"
                }

                // Info window
                var contentString =
                    `<div id="map-content" class="map-employee-card">
                        <div class="mb-16px">
                            <h4 class="fw-600 font-size-20 line-height-24 mb-6px">${employee.name}</h4> 
                            ${included}
                        </div>
                        <div class="p-16px border-1 border-light-grey br-8 flex flex-column align-items-center justify-content-center text-align-center"> 
                            <h5 class="form-label">Address</h5>
                            <p class="fw-400 font-size-14 letter-spacing-2 line-height-20">${employee.address.street1},
                                ${employee.address.city}, ${employee.address.state}</p>
                            <h5 class="form-label">Role</h5>
                            <p class="mb-0 fw-400 font-size-14 letter-spacing-2 line-height-20 text-capitalize">${employee.role}</p>
                        </div>
                    </div>`
                    ;


                // Listeners
                marker.addListener('click', function (e) {
                    var markerElement = e.domEvent.target

                    $.each(employeeMarkers, function (key, marker) {
                        $(marker.element).find(".employee-tag").removeClass("active-employee-tag")
                    })

                    $(markerElement).toggleClass("active-employee-tag")

                    if (infowindow) {
                        infowindow.close();
                    }

                    infowindow = new google.maps.InfoWindow({
                        content: contentString
                    });
                    infowindow.open(map, marker);
                    activeMarker = marker;
                });
                return marker
            });

            google.maps.event.addListener(map, 'click', function () {
                $.each(employeeMarkers, function (key, marker) {
                    $(marker.element).find(".employee-tag").removeClass("active-employee-tag")
                })
                if (infowindow) {
                    infowindow.close();
                }
            })

            $("#map-loading").attr('style', 'display:none!important');

        })
    }


    window.getMarkers = async function () {

        const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary("marker");

        $.ajax({
            url: `/companies/${slug}/markers.json`
        }).done(function (res) {
            var markerPoints = res
            var mapMarkers = markerPoints.map(function (markerPoint, i) {
                if (markerPoint.marker_logo == null) {
                    var markerPointTag = document.createElement("div");
                    markerPointTag.className = "marker-point-tag shadow-black";
                    $(markerPointTag).css("background-color", markerPoint.color)
                } else {
                    var markerPointTag = document.createElement("div");
                    //$(markerPointTag).addClass("bg-white p-10px br-8 border-1 border-light-grey shadow-black")
                    $(markerPointTag).html(`<img src=${markerPoint.marker_logo} class='max-h-40px' style='max-width: 100px!important'>`)
                }
                var marker = new AdvancedMarkerElement({
                    // position: { lat: location.address.lat, lng: location.address.lng },
                    position: new google.maps.LatLng(markerPoint.address.lat, markerPoint.address.lng),
                    map: map,
                    content: markerPointTag
                });

                

                marker.layerId = markerPoint.layer_id

                // Info window
                var contentString =
                    `<div id="map-content" class="map-marker-card">` +
                    `<div class="text-left">`

                if (share) {
                    contentString += `<p class="fw-600 font-size-18 mb-0px">${markerPoint.name}</p><span class="font-size-14 color-grey">${markerPoint.layer}</span>`
                } else {
                    contentString +=
                        `<a href="/companies/${slug}/markers/${markerPoint.id}/edit?pin=true" class="t-decoration-none" data-remote="true">
                            <p class="fw-600 font-size-18 mb-0px">${markerPoint.name}</p>
                        </a>
                        <span class="font-size-14 color-grey">${markerPoint.layer}</span>`
                        
                }
                contentString += `<p class="m-0px mt-5px fw-400 font-size-12 color-grey">${markerPoint.full_address}</p>` + `</div>`

                if (markerPoint.description) {
                    contentString +=
                        `<div class="max-w-250px mt-5px text-left">
                            <p class="m-0px fw-400 font-size-12">${markerPoint.description}</p>
                        </div>`
                }
                contentString += `</div>`;

                // Listeners
                marker.addListener('click', function (e) {
                    var markerElement = e.domEvent.target

                    if (markerInfoWindow) {
                        markerInfoWindow.close();
                    }

                    markerInfoWindow = new google.maps.InfoWindow({
                        content: contentString,
                        pixelOffset: new google.maps.Size(0, 15) // Adjust the -30 value as needed
                    });

                    markerInfoWindow.open(map, marker);
                });
                return marker
            });

            google.maps.event.addListener(map, 'click', function () {
                if (markerInfoWindow) {
                    markerInfoWindow.close();
                }
            })

            window.toggleLayer = async function (layer_id) {
                
                $.each(mapMarkers, function (key, marker) {
                    
                    if (marker.layerId == layer_id) {
                        if (marker.map) {
                            marker.map = null;
                          } else {
                            marker.map = map;
                        }
                    }
                });
            }
        })

        
    }

    function isMobileDevice() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    }

    window.toggleGroupMenu = function () {
        if ($(".group-menu").hasClass("d-none")) {
            $(".group-menu").removeClass("d-none")
            $("#group-caret svg").css("transform", "rotate(180deg)")
        } else {
            $(".group-menu").addClass("d-none")
            $("#group-caret svg").css("transform", "rotate(0deg)")
        }
    }


});